import { ApolloQueryResult } from '@apollo/client';
import { isMobile, isAndroid } from 'react-device-detect';
import { GetDocumentFullQuery, ProductApp, models } from 'skiff-front-graphql';

import { MobileUserDataVersion } from '../types';

export type NativeEditorActiveDoc = ApolloQueryResult<GetDocumentFullQuery>['data']['document'];

declare global {
  interface Window {
    ReactNativeWebView: any;
    IsSkiffWindowsDesktop: boolean;
    // Native editor variables - used only in native editor - are all injected by the native app
    nativeUserData?: models.User;
    activeApp?: ProductApp;
    autoFocus?: boolean;
    activeDoc?: NativeEditorActiveDoc;
    activeDocID?: string;
    offlineNativeEditor?: boolean;
    updateActiveDoc?: (doc: NativeEditorActiveDoc) => void;
    commentID?: string;
    webviewRequests?: Record<string, ((params: any) => void)[] | undefined>;
    refetchBaseDocs?: (() => Promise<void>)[];
  }
}

// Is mobile and in a react native webview
export const isMobileApp = () => isMobile && !!window.ReactNativeWebView;

export const isReactNativeDesktopApp = () => !isMobile && !!window.ReactNativeWebView;
export const isWindowsDesktopApp = () => !isMobile && !!window.IsSkiffWindowsDesktop;
export const isDesktopApp = () => isWindowsDesktopApp() || isReactNativeDesktopApp();

export const isNativeEditor = process.env.IS_NATIVE_EDITOR;
export const isNativeEditorOffline = isNativeEditor && window.offlineNativeEditor;

// Is mobile but not in a react native webview
export const isMobileWebBrowser = () => isMobile && !window.ReactNativeWebView;

export const sendRNWebviewMsg = (type: string, payload: Record<string, any>) => {
  if (!isMobileApp() && !isReactNativeDesktopApp()) return;
  if (!window.ReactNativeWebView) return;
  window.ReactNativeWebView.postMessage(JSON.stringify({ type, payload }));
};

export const copyToClipboardWebAndMobile = (textToCopy: string) => {
  if (isAndroid && isMobileApp()) {
    sendRNWebviewMsg('copy', { copiedText: textToCopy });
    return;
  }
  void navigator.clipboard.writeText(textToCopy);
};

export const sendUserDataToMobileApp = (user: models.User) => {
  try {
    // User Object To Save On Native App
    const legacyUserData = {
      privateUserData: {
        privateKey: user.privateUserData.privateKey,
        signingPrivateKey: user.privateUserData.signingPrivateKey,
        documentKey: user.privateUserData.documentKey
      },
      publicKey: user.publicKey,
      userID: user.userID,
      username: user.username,
      publicData: user.publicData,
      signingPublicKey: user.signingPublicKey,
      version: MobileUserDataVersion.V0
    };
    const userData = {
      ...legacyUserData,
      fullUserData: user // we want to send all userdata and pick what we want to save on native side
    };
    sendRNWebviewMsg('userLoggedIn', userData);
  } catch (error) {
    console.error('Failed to send user data to mobile app:', error);
  }
};

export const createWebviewRequest = (requestID: string, callback: (params: any) => void) => {
  if (!window.webviewRequests) throw new Error('Requests not initialized');
  if (!window.webviewRequests[requestID]) window.webviewRequests[requestID] = [callback];
};

export const flushWebviewRequest = (requestID: string) => {
  if (window.webviewRequests) delete window.webviewRequests[requestID];
};
