import { CircularProgress, Size } from 'nightwatch-ui';
import React from 'react';
import { AppThemeProvider } from 'skiff-front-utils';
import styled from 'styled-components';

const Centered = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

/**
 * This component is rendered while main App component is rendering
 * CircularProgress is imported directly from its source to reduce load time and bundle size
 */
export default function InitialAppLoad() {
  return (
    <AppThemeProvider>
      <Centered>
        <CircularProgress size={Size.LARGE} spinner />
      </Centered>
    </AppThemeProvider>
  );
}
