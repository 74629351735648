import React, { Suspense } from 'react';
import { render } from 'react-dom';
import smoothscroll from 'smoothscroll-polyfill';

import './app.global.css';
import 'nightwatch-ui/dist/esm/index.css';
import InitialAppLoad from './InitialLoadApp';

// Polyfill the smoothscroll for browsers
// without native support
smoothscroll.polyfill();

const Root = React.lazy(() => import('./containers/Root'));

document.addEventListener('DOMContentLoaded', () => {
  render(
    <Suspense fallback={<InitialAppLoad />}>
      <Root />
    </Suspense>,
    document.getElementById('root')
  );
});
